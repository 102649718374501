<template>
  <div style="background-color: lightgrey">
    <section class="container is-light">
      <div class="section">
        <p class="title">Terms and Conditions</p>
      </div>
      <div class="content" style="padding-left: 24px; padding-right: 24px">
        <p class="is-small has-text-right">
          Updated on: 1st October 2020.
          <br />
          Effective Date: 1st October 2020.
        </p>
        <p class="has-text-justified">
          THIS IS AN AGREEMENT BETWEEN YOU OR THE ENTITY THAT YOU REPRESENT
          (hereinafter “You” or “Your”) AND Qbrics, Inc (hereinafter “Qbrics”)
          GOVERNING YOUR USE OF Qbrics SUITE OF ONLINE BUSINESS PRODUCTIVITY AND
          COLLABORATION SOFTWARE.
        </p>
        <h3>PARTS OF THIS AGREEMENT</h3>
        <p class="has-text-justified">
          This Agreement consists of the following terms and conditions
          (hereinafter the “General Terms”) and terms and conditions, if any,
          specific to use of individual Services (hereinafter the “Service
          Specific Terms”). The General Terms and Service Specific Terms are
          collectively referred to as the “Terms”. In the event of a conflict
          between the General Terms and Service Specific Terms, the Service
          Specific Terms shall prevail.
        </p>
        <h3>ACCEPTANCE OF THE TERMS</h3>
        <p class="has-text-justified">
          You must be of legal age to enter into a binding agreement in order to
          accept the Terms. If you do not agree to the General Terms, do not use
          any of our Services. If you agree to the General Terms and do not
          agree to any Service Specific Terms, do not use the corresponding
          Service. You can accept the Terms by clicking on the continue button
          indicating your acceptance of the terms or by actually using the
          Services.
        </p>
        <h3>DESCRIPTION OF SERVICE</h3>
        <p class="has-text-justified">
          We provide a service fronted by a software that enables digital
          signing of documents, transparency of workflows, and verification of
          documents and workflows by multiple parties including third parties
          backed by blockchain enabled trust ("Service" or "Services"). You may
          use the Services for your personal and business use or for internal
          business purpose in the organization that you represent. You may
          connect to the Services using any Internet browser supported by the
          Services. You are responsible for obtaining access to the Internet and
          the equipment necessary to use the Services. You can operate the
          service using our software with your user account and if you choose to
          do so, you can share the software or outputs of the software with
          others.
        </p>

        <b-collapse
          :open="false"
          position="is-bottom"
          aria-id="contentIdForA11y1"
        >
          <a
            slot="trigger"
            slot-scope="props"
            aria-controls="contentIdForA11y1"
          >
            <!-- <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon> -->
            {{ !props.open ? "More" : "Less" }}
          </a>
          <h3>SUBSCRIPTION TO BETA SERVICE</h3>
          <p class="has-text-justified">
            We may offer certain Services as closed or open beta services ("Beta
            Service" or “Beta Services”) for the purpose of testing and
            evaluation. You agree that we have the sole authority and discretion
            to determine the period of time for testing and evaluation of Beta
            Services. We will be the sole judge of the success of such testing
            and the decision, if any, to offer the Beta Services as commercial
            services. You will be under no obligation to acquire a subscription
            to use any paid Service as a result of your subscription to any Beta
            Service. We reserve the right to fully or partially discontinue, at
            any time and from time to time, temporarily or permanently, any of
            the Beta Services with or without notice to you. You agree that
            Qbrics will not be liable to you or to any third party for any harm
            related to, arising out of, or caused by the modification,
            suspension or discontinuance of any of the Beta Services for any
            reason.
          </p>
          <h3>MODIFICATION OF TERMS OF SERVICE</h3>
          <p class="has-text-justified">
            We may modify the Terms upon notice to you at any time through a
            service announcement or by sending email to your primary email
            address. If we make significant changes to the Terms that affect
            your rights, you will be provided with at least 30 days advance
            notice of the changes by email to your primary email address. You
            may terminate your use of the Services by providing Qbrics notice by
            email within 30 days of being notified of the availability of the
            modified Terms if the Terms are modified in a manner that
            substantially affects your rights in connection with use of the
            Services. In the event of such termination, you will be entitled to
            prorated refund of the unused portion of any prepaid fees. Your
            continued use of the Service after the effective date of any change
            to the Terms will be deemed to be your agreement to the modified
            Terms.
          </p>
          <h3>USER SIGN UP OBLIGATIONS</h3>
          <p class="has-text-justified">
            You need to sign up for a user account by providing all required
            information in order to access or use the Services. If you represent
            an organization and wish to use the Services for corporate internal
            use, we recommend that you, and all other users from your
            organization, sign up for user accounts by providing your corporate
            contact information. In particular, we recommend that you use your
            corporate email address. You agree to: a) provide true, accurate,
            current and complete information about yourself as prompted by the
            sign up process; and b) maintain and promptly update the information
            provided during sign up to keep it true, accurate, current, and
            complete. If you provide any information that is untrue, inaccurate,
            outdated, or incomplete, or if Qbrics has reasonable grounds to
            suspect that such information is untrue, inaccurate, outdated, or
            incomplete, Qbrics may terminate your user account and refuse
            current or future use of any or all of the Services.
          </p>
          <h3>ORGANIZATION ACCOUNTS AND ADMINISTRATORS</h3>
          <p class="has-text-justified">
            When you sign up for an account for your organization you may
            specify one or more administrators. The administrators will have the
            right to configure the Services based on your requirements and
            manage end users in your organization account. If your organization
            account is created and configured on your behalf by a third party,
            it is likely that such third party has assumed administrator role
            for your organization. Make sure that you enter into a suitable
            agreement with such third party specifying such party’s roles and
            restrictions as an administrator of your organization account.
            <br /><br />You are responsible for i) ensuring confidentiality of
            your organization account password, ii) appointing competent
            individuals as administrators for managing your organization
            account, and iii) ensuring that all activities that occur in
            connection with your organization account comply with this
            Agreement. You understand that Qbrics is not responsible for account
            administration and internal management of the Services for you.
            <br /><br />You are responsible for taking necessary steps for
            ensuring that your organization does not lose control of the
            administrator accounts. You may specify a process to be followed for
            recovering control in the event of such loss of control of the
            administrator accounts by sending an email to
            <a target="_blank" href="mailto:legal@qbrics.com"
              >legal@qbrics.com</a
            >, provided that the process is acceptable to Qbrics. In the absence
            of any specified administrator account recovery process, Qbrics may
            provide control of an administrator account to an individual
            providing proof satisfactory to Qbrics demonstrating authorization
            to act on behalf of the organization. You agree not to hold Qbrics
            liable for the consequences of any action taken by Qbrics in good
            faith in this regard.
          </p>
          <h3>PERSONAL INFORMATION AND PRIVACY</h3>
          <p class="has-text-justified">
            Personal information you provide to Qbrics through the Service is
            governed by <a href="/privacypolicy">Privacy Policy</a>. Your
            election to use the Service indicates your acceptance of the terms
            of the <a href="/privacypolicy">Privacy Policy</a>. You are
            responsible for maintaining confidentiality of your username,
            password and other sensitive information. You are responsible for
            all activities that occur in your user account and you agree to
            inform us immediately of any unauthorized use of your user account
            by email to
            <a target="_blank" href="mailto:billing@qbrics.com"
              >billing@qbrics.com</a
            >
            or by calling us on any of the numbers listed on
            <a href="https://www.qbrics.com">www.qbrics.com</a>. <br /><br />We
            are not responsible for any loss or damage to you or to any third
            party incurred as a result of any unauthorized access and/or use of
            your user account, or otherwise.
          </p>
          <h3>COMMUNICATIONS FROM QBRICS</h3>
          <p class="has-text-justified">
            The Service may include certain communications from Qbrics, such as
            service announcements, administrative messages and newsletters. You
            understand that these communications shall be considered part of
            using the Services. As part of our policy to provide you total
            privacy, we also provide you the option of opting out from receiving
            newsletters from us. However, you will not be able to opt-out from
            receiving service announcements and administrative messages.
          </p>
          <h3>COMPLAINTS</h3>
          <p class="has-text-justified">
            If we receive a complaint from any person against you with respect
            to your activities as part of use of the Services, we will forward
            the complaint to the primary email address of your user account. You
            must respond to the complainant directly within 10 days of receiving
            the complaint forwarded by us and copy Qbrics in the communication.
            If you do not respond to the complainant within 10 days from the
            date of our email to you, we may disclose your name and contact
            information to the complainant for enabling the complainant to take
            legal action against you. You understand that your failure to
            respond to the forwarded complaint within the 10 days’ time limit
            will be construed as your consent to disclosure of your name and
            contact information by Qbrics to the complainant.
          </p>
          <h3>FEES AND PAYMENTS</h3>
          <p class="has-text-justified">
            The Services are available under subscription plans of various
            durations. Payments for subscription plans of duration of less than
            a year can be made only by Credit Card. Your subscription will be
            automatically renewed at the end of each subscription period unless
            you downgrade your paid subscription plan to a free plan or inform
            us that you do not wish to renew the subscription. At the time of
            automatic renewal, the subscription fee will be charged to the
            Credit Card last used by you. We provide you the option of changing
            the details if you would like the payment for the renewal to be made
            through a different Credit Card. If you do not wish to renew the
            subscription, you must inform us at least seven days prior to the
            renewal date. If you have not downgraded to a free plan and if you
            have not informed us that you do not wish to renew the subscription,
            you will be presumed to have authorized Qbrics to charge the
            subscription fee to the Credit Card last used by you.
            <br /><br />From time to time, we may change the price of any
            Service or charge for use of Services that are currently available
            free of charge. Any increase in charges will not apply until the
            expiry of your then current billing cycle. You will not be charged
            for using any Service unless you have opted for a paid subscription
            plan.
          </p>
          <h3>RESTRICTIONS AND RESPONSIBILITIES</h3>
          <p class="has-text-justified">
            Customer will not, directly or indirectly: reverse engineer,
            de-compile, disassemble or otherwise attempt to discover the source
            code, object code or underlying structure, ideas, know-how or
            algorithms relevant to the Services or any software, documentation
            or data related to the Services (“Software”); modify, translate, or
            create derivative works based on the Services or any Software
            (except to the extent expressly permitted by Company or authorized
            within the Services); use the Services or any Software for
            timesharing or service bureau purposes or otherwise for the benefit
            of a third party; or remove any proprietary notices or labels. With
            respect to any Software that is distributed or provided to Customer
            for use on Customer premises or devices, Company hereby grants
            Customer a non-exclusive, non-transferable, non-sublicensable
            license to use such Software during the Term only in connection with
            the Service.
            <br /><br />Further, Customer may not remove or export from the
            United States or allow the export or re-export of the Services,
            Software or anything related thereto, or any direct product thereof
            in violation of any restrictions, laws or regulations of the United
            States Department of Commerce, the United States Department of
            Treasury Office of Foreign Assets Control, or any other United
            States or foreign agency or authority. As defined in FAR section
            2.101, the Software and documentation are “commercial items” and
            according to DFAR section 252.227 7014(a)(1) and (5) are deemed to
            be “commercial computer software” and “commercial computer software
            documentation.” Consistent with DFAR section 227.7202 and FAR
            section 12.212, any use modification, reproduction, release,
            performance, display, or disclosure of such commercial software or
            commercial software documentation by the U.S. Government will be
            governed solely by the terms of this Agreement and will be
            prohibited except to the extent expressly permitted by the terms of
            this Agreement <br /><br />In addition to all other terms and
            conditions of this Agreement, you shall not: (i) transfer the
            Services or otherwise make it available to any third party; (ii)
            provide any service based on the Services without prior written
            permission; (iii) use the third party links to sites without
            agreeing to their website terms & conditions; (iv) post links to
            third party sites or use their logo, company name, etc. without
            their prior written permission; (v) publish any personal or
            confidential information belonging to any person or entity without
            obtaining consent from such person or entity; (vi) use the Services
            in any manner that could damage, disable, overburden, impair or harm
            any server, network, computer system, resource of Qbrics; (vii)
            violate any applicable local, state, national or international law;
            and (viii) create a false identity to mislead any person as to the
            identity or origin of any communication. <br /><br />Customer shall
            be responsible for obtaining and maintaining any equipment and
            ancillary services needed to connect to, access or otherwise use the
            Services, including, without limitation, modems, hardware, servers,
            software, operating systems, networking, web servers and the like
            (collectively, “Equipment”). Customer shall also be responsible for
            maintaining the security of the Equipment, Customer account,
            passwords (including but not limited to administrative and user
            passwords) and files, and for all uses of Customer account or the
            Equipment with or without Customer’s knowledge or consent.
          </p>
          <h3>SPAMMING AND ILLEGAL ACTIVITIES</h3>
          <p class="has-text-justified">
            You agree to be solely responsible for the contents of your
            transmissions through the Services. You agree not to use the
            Services for illegal purposes or for the transmission of material
            that is unlawful, defamatory, harassing, libelous, invasive of
            another's privacy, abusive, threatening, harmful, vulgar,
            pornographic, obscene, or is otherwise objectionable, offends
            religious sentiments, promotes racism, contains viruses or malicious
            code, or that which infringes or may infringe intellectual property
            or other rights of another. You agree not to use the Services for
            the transmission of "junk mail", "spam", "chain letters", “phishing”
            or unsolicited mass distribution of email. We reserve the right to
            terminate your access to the Services if there are reasonable
            grounds to believe that you have used the Services for any illegal
            or unauthorized activity.
          </p>
          <h3>INACTIVE USER ACCOUNTS POLICY</h3>
          <p class="has-text-justified">
            We reserve the right to terminate unpaid user accounts that are
            inactive for a continuous period of 120 days. In the event of such
            termination, all data associated with such user account will be
            deleted. We will provide you prior notice of such termination and
            option to back-up your data. The data deletion policy may be
            implemented with respect to any or all of the Services. Each Service
            will be considered an independent and separate service for the
            purpose of calculating the period of inactivity. In other words,
            activity in one of the Services is not sufficient to keep your user
            account in another Service active. In case of accounts with more
            than one user, if at least one of the users is active, the account
            will not be considered inactive.
          </p>
          <h3>CONFIDENTIALITY; PROPRIETARY RIGHTS</h3>
          <p class="has-text-justified">
            Each party (the “Receiving Party”) understands that the other party
            (the “Disclosing Party”) has disclosed or may disclose business,
            technical or financial information relating to the Disclosing
            Party’s business (hereinafter referred to as “Proprietary
            Information” of the Disclosing Party). Proprietary Information of
            Company includes non-public information regarding features,
            functionality and performance of the Service. Proprietary
            Information of Customer includes non-public data provided by
            Customer to Company to enable the provision of the Services
            (“Customer Data”). The Receiving Party agrees: (i) to take
            reasonable precautions to protect such Proprietary Information, and
            (ii) not to use (except in performance of the Services or as
            otherwise permitted herein) or divulge to any third person any such
            Proprietary Information. The Disclosing Party agrees that the
            foregoing shall not apply with respect to any information after five
            (5) years following the disclosure thereof or any information that
            the Receiving Party can document (a) is or becomes generally
            available to the public, or (b) was in its possession or known by
            its prior to receipt from the Disclosing Party, or (c) was
            rightfully disclosed to it without restriction by a third party, or
            (d) was independently developed without use of any Proprietary
            Information of the Disclosing Party or (e) is required to be
            disclosed by law.
            <br /><br />Customer shall own all right, title and interest in and
            to the Customer Data. Company shall own and retain all right, title
            and interest in and to (a) the Services and Software, all
            improvements, enhancements or modifications thereto, (b) any
            software, applications, inventions or other technology developed in
            connection with Implementation Services or support, and (c) all
            intellectual property rights related to any of the foregoing.
            <br /><br />Notwithstanding anything to the contrary, Company shall
            have the right collect and analyze data and other information
            relating to the provision, use and performance of various aspects of
            the Services and related systems and technologies (including,
            without limitation, information concerning Customer Data and data
            derived therefrom), and Company will be free (during and after the
            term hereof) to (i) use such information and data to improve and
            enhance the Services and for other development, diagnostic and
            corrective purposes in connection with the Services and other
            Company offerings, and (ii) disclose such data solely in aggregate
            or other de-identified form in connection with its business.
          </p>
          <h3>DATA OWNERSHIP</h3>
          <p class="has-text-justified">
            We respect your right to ownership of content created or stored by
            you. You own the content created or stored by you. Unless
            specifically permitted by you, your use of the Services does not
            grant Qbrics the license to use, reproduce, adapt, modify, publish
            or distribute the content created by you or stored in your user
            account for Qbrics’s commercial, marketing or any similar purpose.
            But you grant Qbrics permission to access, copy, distribute, store,
            transmit, reformat, publicly display and publicly perform the
            content of your user account solely as required for the purpose of
            providing the Services to you.
          </p>
          <h3>USER GENERATED CONTENT</h3>
          <p class="has-text-justified">
            You may transmit or publish content created by you using any of the
            Services or otherwise. However, you shall be solely responsible for
            such content and the consequences of its transmission or
            publication. Any content made public will be publicly accessible
            through the internet and may be crawled and indexed by search
            engines. You are responsible for ensuring that you do not
            accidentally make any private content publicly available. Any
            content that you may receive from other users of the Services, is
            provided to you AS IS for your information and personal use only and
            you agree not to use, copy, reproduce, distribute, transmit,
            broadcast, display, sell, license or otherwise exploit such content
            for any purpose, without the express written consent of the person
            who owns the rights to such content. In the course of using any of
            the Services, if you come across any content with copyright
            notice(s) or any copy protection feature(s), you agree not to remove
            such copyright notice(s) or disable such copy protection feature(s)
            as the case may be. By making any copyrighted/copyrightable content
            available on any of the Services you affirm that you have the
            consent, authorization or permission, as the case may be from every
            person who may claim any rights in such content to make such content
            available in such manner. Further, by making any content available
            in the manner aforementioned, you expressly agree that Qbrics will
            have the right to block access to or remove such content made
            available by you if Qbrics receives complaints concerning any
            illegality or infringement of third party rights in such content. By
            using any of the Services and transmitting or publishing any content
            using such Service, you expressly consent to determination of
            questions of illegality or infringement of third-party rights in
            such content by the agent designated by Qbrics for this purpose.
            <br /><br />For procedure relating to complaints of illegality or
            infringement of third-party rights in content transmitted or
            published using the Services, please send email to
            <a target="_blank" href="mailto:legal@qbrics.com"
              >legal@qbrics.com</a
            >. <br /><br />If you wish to protest any blocking or removal of
            content by Qbrics, you may do so in the manner provided.
          </p>
          <h3>SAMPLE FILES AND APPLICATIONS</h3>
          <p class="has-text-justified">
            Qbrics may provide sample files and applications for the purpose of
            demonstrating the possibility of using the Services effectively for
            specific purposes. The information contained in any such sample
            files and applications consists of random data. Qbrics makes no
            warranty, either express or implied, as to the accuracy, usefulness,
            completeness or reliability of the information or the sample files
            and applications.
          </p>
          <h3>TRADEMARK</h3>
          <p class="has-text-justified">
            <strong>Qbrics, Qbrics logo,</strong> the names of individual
            Services and their logos are trademarks of Qbrics Corporation. You
            agree not to display or use, in any manner, the Qbrics trademarks,
            without Qbrics’s prior permission.
          </p>
          <h3>DISCLAIMER OF WARRANTIES</h3>
          <p class="has-text-justified">
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE SERVICES IS
            AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN
            AS-IS-AND-AS-AVAILABLE BASIS. QBRICS EXPRESSLY DISCLAIMS ALL
            WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND
            FITNESS FOR A PARTICULAR PURPOSE. QBRICS MAKES NO WARRANTY THAT THE
            SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE. USE
            OF ANY MATERIAL DOWNLOADED OR OBTAINED THROUGH THE USE OF THE
            SERVICES SHALL BE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, MOBILE
            TELEPHONE, WIRELESS DEVICE OR DATA THAT RESULTS FROM THE USE OF THE
            SERVICES OR THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR
            INFORMATION, WHETHER WRITTEN OR ORAL, OBTAINED BY YOU FROM QBRICS,
            ITS EMPLOYEES OR REPRESENTATIVES SHALL CREATE ANY WARRANTY NOT
            EXPRESSLY STATED IN THE TERMS.
          </p>
          <h3>LIMITATION OF LIABILITY</h3>
          <p class="has-text-justified">
            NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY
            OF A PERSON, COMPANY AND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO
            ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES,
            REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE
            OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR
            TERMS AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE,
            STRICT LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF
            USE OR FOR LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF
            PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF
            BUSINESS; (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR
            CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND COMPANY’S
            REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH
            AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY
            CUSTOMER TO COMPANY FOR THE SERVICES UNDER THIS AGREEMENT IN THE 12
            MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH
            CASE, WHETHER OR NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES.
          </p>
          <h3>INDEMNIFICATION</h3>
          <p class="has-text-justified">
            Company shall hold Customer harmless from liability to third parties
            resulting from infringement by the Service of any United States
            patent or any copyright or misappropriation of any trade secret,
            provided Company is promptly notified of any and all threats, claims
            and proceedings related thereto and given reasonable assistance and
            the opportunity to assume sole control over defense and settlement;
            Company will not be responsible for any settlement it does not
            approve in writing. The foregoing obligations do not apply with
            respect to portions or components of the Service (i) not supplied by
            Company, (ii) made in whole or in part in accordance with Customer
            specifications, (iii) that are modified after delivery by Company,
            (iv) combined with other products, processes or materials where the
            alleged infringement relates to such combination, (v) where Customer
            continues allegedly infringing activity after being notified thereof
            or after being informed of modifications that would have avoided the
            alleged infringement, or (vi) where Customer’s use of the Service is
            not strictly in accordance with this Agreement. If, due to a claim
            of infringement, the Services are held by a court of competent
            jurisdiction to be or are believed by Company to be infringing,
            Company may, at its option and expense (a) replace or modify the
            Service to be non-infringing provided that such modification or
            replacement contains substantially similar features and
            functionality, (b) obtain for Customer a license to continue using
            the Service, or (c) if neither of the foregoing is commercially
            practicable, terminate this Agreement and Customer’s rights
            hereunder and provide Customer a refund of any prepaid, unused fees
            for the Service.
          </p>
          <h3>ARBITRATION</h3>
          <p class="has-text-justified">
            Any controversy or claim arising out of or relating to the Terms
            shall be settled by binding arbitration in accordance with the
            commercial arbitration rules of the American Arbitration
            Association. Any such controversy or claim shall be arbitrated on an
            individual basis and shall not be consolidated in any arbitration
            with any claim or controversy of any other party. The decision of
            the arbitrator shall be final and unappealable. The arbitration
            shall be conducted in the State of Delaware and judgment on the
            arbitration award may be entered into any court having jurisdiction
            thereof. Notwithstanding anything to the contrary, Qbrics may at any
            time seek injunctions or other forms of equitable relief from any
            court of competent jurisdiction.
          </p>
          <h3>SUSPENSION AND TERMINATION</h3>
          <p class="has-text-justified">
            We may suspend your user account or temporarily disable access to
            whole or part of any Service in the event of any suspected illegal
            activity, extended periods of inactivity or requests by law
            enforcement or other government agencies. Objections to suspension
            or disabling of user accounts should be made to
            <a target="_blank" href="mailto:legal@qbrics.com"
              >legal@qbrics.com</a
            >
            within thirty days of being notified about the suspension. We may
            terminate a suspended or disabled user account after thirty days. We
            will also terminate your user account on your request.
            <br /><br />In addition, we reserve the right to terminate your user
            account and deny the Services upon reasonable belief that you have
            violated the Terms and to terminate your access to any Beta Service
            in case of unexpected technical issues or discontinuation of the
            Beta Service. You have the right to terminate your user account if
            Qbrics breaches its obligations under these Terms and in such event,
            you will be entitled to prorated refund of any prepaid fees.
            Termination of user account will include denial of access to all
            Services, deletion of information in your user account such as your
            email address and password and deletion of all data in your user
            account.
          </p>
          <h3>MISCELLANEOUS</h3>
          <p class="has-text-justified">
            If any provision of this Agreement is found to be unenforceable or
            invalid, that provision will be limited or eliminated to the minimum
            extent necessary so that this Agreement will otherwise remain in
            full force and effect and enforceable. This Agreement is not
            assignable, transferable or sublicensable by Customer except with
            Company’s prior written consent. Company may transfer and assign any
            of its rights and obligations under this Agreement without consent.
            This Agreement is the complete and exclusive statement of the mutual
            understanding of the parties and supersedes and cancels all previous
            written and oral agreements, communications and other understandings
            relating to the subject matter of this Agreement, and that all
            waivers and modifications must be in a writing signed by both
            parties, except as otherwise provided herein. No agency,
            partnership, joint venture, or employment is created as a result of
            this Agreement and Customer does not have any authority of any kind
            to bind Company in any respect whatsoever. In any action or
            proceeding to enforce rights under this Agreement, the prevailing
            party will be entitled to recover costs and attorneys’ fees. All
            notices under this Agreement will be in writing and will be deemed
            to have been duly given when received, if personally delivered; when
            receipt is electronically confirmed, if transmitted by facsimile or
            e-mail; the day after it is sent, if sent for next day delivery by
            recognized overnight delivery service; and upon receipt, if sent by
            certified or registered mail, return receipt requested. This
            Agreement shall be governed by the laws of the State of Delaware
            without regard to its conflict of law's provisions.
          </p>
          <h3>END OF TERMS OF SERVICE</h3>
          <p class="has-text-justified">
            If you have any questions or concerns regarding this Agreement,
            please contact us at
            <a target="_blank" href="mailto:legal@qbrics.com"
              >legal@qbrics.com</a
            >.
          </p>
          <br />
        </b-collapse>
        <br /><br />
      </div>
    </section>
  </div>
</template>
<script>
export default {
  props: {
    open: Boolean,
  },
};
</script>
<style scoped>
.content {
  margin-bottom: 0 !important;
}
</style>
